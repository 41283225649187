import {
  getHumanDateFormat,
  getHumanTimeFormat,
  getWeekday,
  parseTimeAsDate,
  WEEKDAYS,
} from '@mr-yum/frontend-core/dist/support/dates'
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  ErrorNotification,
  IconButton,
  SVGNoOrderingSlot,
} from '@mr-yum/frontend-ui'
import { NotFound } from 'components/Common/NotFound'
import { OrderingWindowsFormLoader } from 'components/Ordering/OrderingWindowsFormLoader'
import {
  differenceInCalendarDays,
  format,
  formatISO9075,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  startOfDay,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import {
  AvailableSlot,
  OrderingType,
  useAddOrUpdateWindowOnCartMutation,
  useVenueOrderingWindowsQuery,
} from 'lib/gql'
import findLast from 'lodash/findLast'
import last from 'lodash/last'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { AsapButton } from './AsapButton'
import { getDate } from './getDate'

interface Props {
  venueSlug: string
  orderingType: OrderingType
  returnTo?: 'cart' | 'payment' | undefined
  onClose?: () => void
  startDate?: string | null
}

export type Window = Pick<
  AvailableSlot,
  'id' | 'startTime' | 'orderingWindowStartDate' | 'endTime'
>

const OrderingWindowsFormInner = ({
  venueSlug,
  orderingType,
  onClose,
  startDate,
}: Props) => {
  const intl = useIntl()

  const [{ data, error, fetching }] = useVenueOrderingWindowsQuery({
    variables: { venueSlug, orderingType },
  })
  const venue = data?.guestVenue

  const [{ fetching: loadingData, data: updateData }, addOrUpdateWindowOnCart] =
    useAddOrUpdateWindowOnCartMutation()

  const orderingWindowSettings =
    data?.venue?.orderingTypeSettingsV2?.orderingWindow

  const orderingWindowStartDate =
    updateData?.addOrUpdateWindowOnCart.orderingWindowStartDate || startDate

  const firstWindow =
    orderingWindowSettings?.availableOrderingWindows[0]?.orderingWindowStartDate

  const lastWindow = orderingWindowSettings?.availableOrderingWindows
    ? last(orderingWindowSettings.availableOrderingWindows)
        ?.orderingWindowStartDate
    : undefined

  const [selected, setSelected] = useState<Window | null | undefined>(undefined)

  const [date, setDate] = useState<Date>(
    orderingWindowStartDate
      ? parseISO(orderingWindowStartDate)
      : firstWindow
        ? parseISO(firstWindow)
        : utcToZonedTime(getDate(), venue?.timezone || 'Australia/Melbourne'),
  )
  const dateDay = getWeekday(date)

  useEffect(() => {
    if (firstWindow && !orderingWindowStartDate) {
      setDate(parseISO(firstWindow))
    }
    // only react on firstWindow change (on load)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstWindow])

  useEffect(() => {
    if (orderingWindowStartDate) {
      setDate(parseISO(orderingWindowStartDate))
    }
  }, [orderingWindowStartDate])

  const nextDay = useMemo(() => {
    if (
      !lastWindow ||
      differenceInCalendarDays(date, parseISO(lastWindow)) >= 0
    ) {
      return undefined
    }

    const nextWindowDate =
      orderingWindowSettings?.availableOrderingWindows.find((window) =>
        isAfter(startOfDay(parseISO(window.orderingWindowStartDate)), date),
      )?.orderingWindowStartDate

    if (nextWindowDate) {
      return () => setDate(parseISO(nextWindowDate))
    }

    return undefined
  }, [date, lastWindow, orderingWindowSettings?.availableOrderingWindows])

  const prevDay = useMemo(() => {
    if (
      !firstWindow ||
      differenceInCalendarDays(date, parseISO(firstWindow)) <= 0
    ) {
      return undefined
    }

    const prevWindow = findLast(
      orderingWindowSettings?.availableOrderingWindows,
      (window) =>
        isBefore(parseISO(window.orderingWindowStartDate), startOfDay(date)),
    )?.orderingWindowStartDate

    if (prevWindow) {
      return () => setDate(parseISO(prevWindow))
    }

    return undefined
  }, [date, firstWindow, orderingWindowSettings?.availableOrderingWindows])

  const handleConfirm = useCallback(async () => {
    if (selected === null) {
      await addOrUpdateWindowOnCart({
        input: {
          orderingType,
          venueSlug,
          orderingWindowStartDate: null,
          orderingWindowEndDate: null,
          orderingWindowId: null,
        },
      })
    } else if (selected) {
      await addOrUpdateWindowOnCart({
        input: {
          orderingType,
          venueSlug,
          orderingWindowStartDate: formatISO9075(
            parseTimeAsDate(date, selected.startTime),
          ),
          orderingWindowEndDate: formatISO9075(
            parseTimeAsDate(date, selected.endTime, true),
          ),
          orderingWindowId: selected.id,
        },
      })
    }
    onClose?.()
  }, [
    selected,
    onClose,
    addOrUpdateWindowOnCart,
    orderingType,
    venueSlug,
    date,
  ])

  const handleSelected = useCallback((item: Window | null) => {
    setSelected(item)
  }, [])

  const orderingWindows: AvailableSlot[] = useMemo(
    () =>
      orderingWindowSettings?.enabled === true
        ? orderingWindowSettings?.availableOrderingWindows
            ?.filter((window) =>
              isEqual(
                startOfDay(parseISO(window.orderingWindowStartDate)),
                startOfDay(date),
              ),
            )
            .filter((window) => !window.isPast)
        : [],
    [
      date,
      orderingWindowSettings?.availableOrderingWindows,
      orderingWindowSettings?.enabled,
    ],
  )

  if (!venue && !fetching) return <NotFound />

  if (!venue) {
    return <OrderingWindowsFormLoader />
  }

  const hasAsap = orderingWindowSettings?.asapWindowEnabled

  return (
    <>
      {error && <ErrorNotification fullWidth error={error} />}

      <div className="divide-y">
        <div className="flex justify-between px-4 py-3">
          <IconButton
            disabled={!prevDay}
            onClick={prevDay}
            aria-label="Previous day"
          >
            <ChevronLeftIcon />
          </IconButton>

          <div className="px-4 text-center" aria-label="Current day">
            <div className="mb-1 my-label-md">{WEEKDAYS[dateDay]}</div>
            <div className="my-body-sm">
              {format(date, getHumanDateFormat())}
            </div>
          </div>

          <IconButton
            disabled={!nextDay}
            onClick={nextDay}
            aria-label="Next day"
          >
            <ChevronRightIcon />
          </IconButton>
        </div>

        {orderingWindows.length ? (
          <div className="relative min-h-[350px] max-w-[524px]">
            <div className="relative mx-auto grid max-h-[350px] w-full grid-cols-2 gap-3 overflow-y-auto px-4 pb-8 pt-3 sm:grid-cols-3">
              <AsapButton
                date={date}
                window={orderingWindows[0]}
                hasAsap={!!hasAsap}
                selected={selected}
                startDate={startDate}
                setSelected={handleSelected}
                loading={loadingData}
              />

              {orderingWindows.map((window, index) => {
                const startTime = parseTimeAsDate(date, window.startTime)
                const endTime = parseTimeAsDate(date, window.endTime, true)

                const available =
                  !window.unlimitedOrders && window.windows !== 0
                const soldOut = !window.unlimitedOrders && window.windows === 0

                const currentStartDate =
                  selected === null
                    ? null
                    : selected?.orderingWindowStartDate || startDate

                const isSelected =
                  window.orderingWindowStartDate === currentStartDate

                return (
                  <div key={index}>
                    <div className="h-full">
                      <Button
                        type="button"
                        variant={isSelected ? 'primary-alternative' : 'outline'}
                        disabled={window.isPast || soldOut}
                        onClick={() => {
                          setSelected(window)
                        }}
                        isLoading={isSelected && loadingData}
                        className="h-full"
                        aria-label={format(startTime, getHumanTimeFormat())}
                        aria-selected={isSelected}
                        fullWidth
                      >
                        <div className="flex min-h-[36px] flex-col items-center justify-center">
                          <div className="mb-1 text-current my-label-sm">
                            <div className="inline whitespace-nowrap">
                              {format(startTime, getHumanTimeFormat())}
                            </div>

                            {orderingType !== OrderingType.PickUp &&
                              orderingType !== OrderingType.Counter && (
                                <>
                                  {' – '}
                                  <div className="inline whitespace-nowrap">
                                    {format(endTime, getHumanTimeFormat())}
                                  </div>
                                </>
                              )}
                          </div>

                          <div className="text-current my-body-sm">
                            {available && !window.isPast && (
                              <>
                                {window.windows} order
                                {window.windows === 1 ? '' : 's'} available
                              </>
                            )}

                            {soldOut && (
                              <FormattedMessage
                                defaultMessage="Sold out!"
                                id="sv1z7k"
                              />
                            )}
                          </div>
                        </div>
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="pointer-events-none absolute bottom-0 left-0 w-full px-4">
              <div className="to-[theme(backgroundColor.surface)]/0 h-8 w-full border-b bg-gradient-to-t from-[theme(backgroundColor.surface)]"></div>
            </div>
          </div>
        ) : (
          <div className="relative flex min-h-[350px] max-w-[524px] flex-col items-center justify-center px-4 pb-5 pt-3 text-center">
            <SVGNoOrderingSlot width="120px" height="117px" />

            <h2 className="mb-3 mt-0 my-label-md">
              <FormattedMessage
                defaultMessage="Oh no! Nothing here..."
                id="FvZ/IA"
              />
            </h2>

            <div className="my-body-sm">
              <FormattedMessage
                defaultMessage="Ordering is not available today. Try selecting another day!"
                id="BdkfGC"
              />
            </div>
          </div>
        )}
      </div>

      <div className="px-4 pb-4 pt-2">
        <Button
          onClick={handleConfirm}
          type="button"
          isLoading={loadingData}
          size="lg"
          disabled={loadingData || (!selected && !startDate && !hasAsap)}
          aria-label={intl.formatMessage({
            defaultMessage: 'Ordering type confirm',
            id: 'Qh+8pr',
          })}
          fullWidth
        >
          <FormattedMessage id="N2IrpM" defaultMessage="Confirm" />
        </Button>
      </div>
    </>
  )
}

export const OrderingWindowsForm = observer(OrderingWindowsFormInner)

OrderingWindowsForm.displayName = 'OrderingWindowsForm'
