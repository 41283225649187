import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
interface Props {
  postcodes: string[]
}

export const AddressPostcodes = ({ postcodes }: Props) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="mb-4 flex items-center justify-end text-foreground">
            <Disclosure.Button className="flex items-center py-1 text-foreground my-body-sm hover:underline">
              {open ? (
                <>
                  <FormattedMessage
                    defaultMessage="Hide delivery areas"
                    id="H6g8U2"
                  />
                </>
              ) : (
                <>
                  <FormattedMessage
                    defaultMessage="Show delivery areas"
                    id="nvYHVs"
                  />
                </>
              )}

              <ChevronDownIcon
                className={cn(
                  'relative top-[1px] ml-1 transition-all duration-300 ease-in-out',
                  {
                    'rotate-180': open,
                  },
                )}
              />
            </Disclosure.Button>
          </div>

          <Transition
            show={open}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel static className="mb-10 text-center my-body-sm">
              <FormattedMessage defaultMessage="Delivering to" id="uwvXj+" />{' '}
              {postcodes.join(', ')}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}
