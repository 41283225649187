import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Button, ErrorFilledIcon } from '@mr-yum/frontend-ui'
import { InputField } from 'components/HookForm/InputField'
import { SelectField } from 'components/HookForm/SelectField'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { useLogger } from 'hooks/useLogger'
import {
  TableNumberValidation,
  useAddOrUpdateTableNumberOnCartMutation,
  VenueTableNumberQuery,
} from 'lib/gql'
import { getCleanPath } from 'lib/utils'
import toString from 'lodash/toString'
import Router, { useRouter } from 'next/router'
import React, { useCallback, useMemo } from 'react'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  getTableNumberRequirement,
  getTableNumberSettings,
  getValidationSchema,
} from './TableNumberUtils'
import { FormValues, TableNumberProps } from './types'

export const TableNumberForm = ({
  venue,
  onClose = () => false,
  number,
  area,
}: TableNumberProps & {
  venue: NonNullable<VenueTableNumberQuery['guestVenue']>
}) => {
  const [, addOrUpdateTableNumberOnCart] =
    useAddOrUpdateTableNumberOnCartMutation()

  const intl = useIntl()
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()
  const { pathname, asPath } = useRouter()

  const onCartPage = pathname.split('/').includes('cart')

  const { logEvent } = useLogger()
  const settings = getTableNumberSettings(intl, venue)
  const hasTableAreas = settings.tableAreas.length > 0

  const defaultValues = useMemo<FormValues>(() => {
    return {
      tableArea: area ?? '',
      tableNumber: number ?? '',
    }
  }, [number, area])

  const form = useForm<FormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(
      getValidationSchema(
        {
          tableText: settings.tableText,
          tableNumberValidation: settings.tableNumberValidation,
          minTableNumber: settings.minTableNumber,
          maxTableNumber: settings.maxTableNumber,
          hasTableAreas,
          tableNumberRequirement: getTableNumberRequirement(venue),
        },
        intl,
      ),
    ),
  })

  const {
    setError,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = form

  const submitHandler = useCallback<SubmitHandler<FormValues>>(
    async ({ tableArea, tableNumber }) => {
      if (onCartPage) {
        logEvent('Clicked confirm on update table number modal in cart')
      }

      const { error } = await addOrUpdateTableNumberOnCart({
        input: {
          venueSlug,
          orderingType,
          tableArea,
          tableNumber: toString(tableNumber),
        },
      })

      if (error) {
        return setError('root.serverError', {
          type: '400',
          message: error.graphQLErrors[0]?.message,
        })
      }

      void Router.replace(
        getCleanPath(pathname),
        asPath ? getCleanPath(asPath) : undefined,
        { shallow: true },
      )

      return onClose()
    },
    [
      onCartPage,
      addOrUpdateTableNumberOnCart,
      venueSlug,
      orderingType,
      pathname,
      asPath,
      onClose,
      logEvent,
      setError,
    ],
  )

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submitHandler)}>
        {errors.root && (
          <Alert
            className="mb-4"
            variant="critical-subtle"
            icon={<ErrorFilledIcon />}
            fullWidth
          >
            {errors.root.serverError.message}
          </Alert>
        )}

        {hasTableAreas && (
          <SelectField
            name="tableArea"
            ariaLabel="tableArea"
            maxDropdownHeight={150}
            clearable={true}
            label={
              settings.tableAreaText ||
              intl.formatMessage({
                defaultMessage: 'Table area',
                id: '6nnIPg',
              })
            }
            options={(settings.tableAreas || []).map((option: string) => ({
              label: option,
              id: option,
            }))}
            autoFocus
          />
        )}

        <Controller
          name="tableNumber"
          render={({ field }) => (
            <InputField
              label={
                settings.tableText ||
                intl.formatMessage({
                  defaultMessage: 'Table number',
                  id: 'SCjZeO',
                })
              }
              name="tableNumber"
              placeholder={
                settings.tableText ||
                intl.formatMessage({
                  defaultMessage: 'Table number',
                  id: 'SCjZeO',
                })
              }
              type={
                settings.tableNumberValidation === TableNumberValidation.Number
                  ? 'number'
                  : 'text'
              }
              inputMode={
                settings.tableNumberValidation === TableNumberValidation.Number
                  ? 'decimal'
                  : undefined
              }
              pattern={
                settings.tableNumberValidation === TableNumberValidation.Number
                  ? '[0-9]*'
                  : undefined
              }
              clearable={true}
              className={{ input: 'no-number-controls' }}
              onClear={() => field.onChange('')}
              autoFocus={!hasTableAreas}
            />
          )}
        />

        <Button
          type="submit"
          fullWidth
          size="lg"
          data-testid="table-number-submit"
          isLoading={isSubmitting}
          aria-label={intl.formatMessage({
            defaultMessage: 'Table number confirm',
            id: 'sljl18',
          })}
        >
          <FormattedMessage id="N2IrpM" defaultMessage="Confirm" />
        </Button>
      </form>
    </FormProvider>
  )
}
