import { IntlShape } from 'react-intl'
import * as Yup from 'yup'

export function getValidationSchema({ formatMessage }: IntlShape) {
  return Yup.object().shape({
    unitNumber: Yup.string().nullable(),
    streetAddress: Yup.string().nullable(),
    googlePlaceId: Yup.string()
      .nullable()
      .required(
        formatMessage({
          defaultMessage: 'Select your address from the dropdown',
          id: 'hvdOjf',
        }),
      ),
  })
}
