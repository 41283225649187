import { useScript } from '@mr-yum/frontend-core/dist/hooks/useScript'
import {
  Combobox,
  ComboboxProps,
  FieldMessage,
  FormControl,
  FormControlProps,
  OnChangeParams,
} from '@mr-yum/frontend-ui'
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useController } from 'react-hook-form'
import usePlacesAutocomplete from 'use-places-autocomplete'

interface Props {
  name: string
  onSelect?: (placeId: string) => void
  googlePlacesApiKey: string
  lat?: number
  lng?: number
  radius?: number
  caption?: string
}

export type AddressFieldProps = Omit<
  ComboboxProps & Pick<FormControlProps, 'label'>,
  'options' | 'onSelect'
> &
  Props

export const AddressField = ({
  name,
  placeholder,
  label,
  onSelect,
  googlePlacesApiKey,
  lat,
  lng,
  radius,
  caption,
  ...props
}: PropsWithChildren<AddressFieldProps>) => {
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${googlePlacesApiKey}&libraries=places&callback=initMap`,
  )

  const [isResolving, setResolving] = useState(false)
  const { field, fieldState } = useController({ name })

  const radiusDefault = lat && lng ? 150_000 : 1_000_000

  const {
    ready,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: loaded
        ? new window.google.maps.LatLng(lat || -25, lng || 134)
        : undefined,
      radius: radius || radiusDefault,
    },
    debounce: 300,
    callbackName: 'initMap',
  })

  const handleInputChange = useCallback<NonNullable<OnChangeParams>>(
    ({ inputValue }) => {
      field.onChange(inputValue)

      if (inputValue && (inputValue as unknown as number) < 3) {
        clearSuggestions()
      } else {
        setValue(inputValue as string)
      }
    },
    [clearSuggestions, field, setValue],
  )

  const handleChange = useCallback<NonNullable<OnChangeParams>>(
    ({ selectedItem }) => {
      if (selectedItem?.id) {
        setResolving(true)
        field.onChange(selectedItem.label)
        setValue(selectedItem?.label as string, false)

        try {
          onSelect?.(selectedItem.id as string)
        } finally {
          setResolving(false)
        }
      }
    },
    [field, onSelect, setValue],
  )

  const handleClear = useCallback(() => {
    field.onChange('')
    setValue('', false)
    onSelect?.('')
  }, [onSelect, setValue, field])

  const value = useMemo(
    () => (field.value ? { label: field.value, id: '' } : undefined),
    [field.value],
  )

  return (
    <FormControl
      label={label}
      caption={caption}
      feedback={
        !!fieldState.error && (
          <FieldMessage type="critical">
            {fieldState.error.message}
          </FieldMessage>
        )
      }
      htmlFor={name}
    >
      <Combobox
        options={(data || []).map((result) => ({
          label: result.description,
          id: result.place_id,
        }))}
        {...props}
        onBlur={field.onBlur}
        value={value}
        error={!!fieldState.error}
        disabled={!ready || isResolving}
        isLoading={!ready || isResolving}
        placeholder={placeholder}
        onInputChange={handleInputChange}
        onInputClear={handleClear}
        onChange={handleChange}
        name={field.name}
        autoComplete="dont-autofill-address"
      />
    </FormControl>
  )
}
