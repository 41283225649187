import { Alert, ErrorFilledIcon } from '@mr-yum/frontend-ui'
import React from 'react'
import { useFormState } from 'react-hook-form'

const MAX_ERRORS = 12

const ErrorItem = ({ message }: { message: string }) => <li>{message}</li>

export const HookFormSubmitErrors = ({
  ignoreErrors = [],
}: {
  ignoreErrors?: string[]
}) => {
  const { isValid, errors, submitCount } = useFormState()

  const messages = Object.entries(errors ?? {}).filter(
    ([errorKey]) => !ignoreErrors.includes(errorKey),
  )

  if (isValid || submitCount < 1 || messages.length < 1) {
    return null
  }

  return (
    <Alert variant="critical-subtle" icon={<ErrorFilledIcon />} fullWidth>
      <ul>
        {messages.slice(0, MAX_ERRORS).map(([_errorKey, error], index) => (
          <ErrorItem key={index} message={error?.message?.toString() ?? ''} />
        ))}
        {messages.length > MAX_ERRORS && (
          <li>and {messages.length - MAX_ERRORS} more problems</li>
        )}
      </ul>
    </Alert>
  )
}
