import {
  CartPartsFragment,
  CartTotal,
  CartValidationError,
  CartValidationErrorV2,
} from 'lib/gql'
import uniqBy from 'lodash/uniqBy'
import { Object } from 'ts-toolbelt'
import { assertUnreachable } from 'utils/assertUnreachable'

import { CartItemValidation } from './CartItemWrapper'

// these are the only errors that weren't in SUPPORTED_CART_VALIDATION_ERRORS previously
// We don't care about them before the payment page as the user can't fix them yet
// User will login before they get to payment page (MissingUser)
// PosAdjustment can't be fixed by the user before payment page as on payment page load as we do the POS revalidation
// Tip has been depreceated by API so it won't happen anyway
export const IGNORED_CART_VALIDATION_ERRORS = [
  CartValidationError.MissingUser,
  CartValidationError.MissingTip,
  CartValidationError.PosAdjustmentRequired,
]

// only "invalid" for errors the user can fix here (e.g. MissingUser is irrelevant
export const isCartInvalidBeforePaymentPage = (
  cartValidationErrors: CartValidationErrorV2[],
) => {
  return (
    cartValidationErrors.filter(
      (error) => !IGNORED_CART_VALIDATION_ERRORS.includes(error.type),
    ).length > 0
  )
}

export type CartValidator = Object.Nullable<
  Pick<
    CartPartsFragment,
    | 'cartValidationErrorsV2'
    | 'tableNumber'
    | 'tableArea'
    | 'orderingWindowStartDate'
    | 'orderingWindowEndDate'
    | 'currentLocation'
  > & {
    total: Pick<
      CartTotal,
      'minFreeDeliveryThresholdNotReached' | 'minDeliveryThresholdNotReached'
    >
  }
>

export const cartValidationV2InitialValue = (cart: CartValidator) => {
  const unavailableItemsErrors: CartValidationErrorV2[] = []

  return {
    shouldSelectOrderingWindow: false,
    shouldAddAddress: false,
    shouldAddTableNumber: false,
    shouldAddValidTableNumber: false,
    shouldAddTableArea: false,
    shouldAddNotes: false,
    unavailableItemsErrors,
    orderingWindowInvalid: false,
    itemPriceChanged: false,
    shouldAddMoreToCart: cart && cart.total?.minDeliveryThresholdNotReached,
    shouldAddMoreForFreeDelivery:
      cart && cart.total?.minFreeDeliveryThresholdNotReached,
    emptyCart: false,
    orderingTypeUnavailable: false,
    purchasedCart: false,
    venueClosed: false,
    venueNotTakingOrders: false,
    shouldAddTip: false,
    tableNumberAllOff: false,
    tableNumberAlcoholOff: false,
    itemQuantityMinError: '',
    itemQuantityMaxError: '',
    invalidLoyaltyDiscount: false,
  }
}

export const getCartValidation = (cart: CartValidator) => {
  const validationErrors = cart?.cartValidationErrorsV2 || []

  if (validationErrors.length === 0) {
    return cartValidationV2InitialValue(cart)
  }

  const result = validationErrors.reduce((acc, error) => {
    const { type: cartValidationError } = error

    switch (cartValidationError) {
      case CartValidationError.MissingOrderingWindow:
        acc.shouldSelectOrderingWindow = true
        break
      case CartValidationError.MissingDeliveryAddress:
        acc.shouldAddAddress = true
        break
      case CartValidationError.MissingTableNumber:
        acc.shouldAddTableNumber = true
        break
      case CartValidationError.InvalidTableNumber:
        acc.shouldAddValidTableNumber = true
        break
      case CartValidationError.MissingTableArea:
        acc.shouldAddTableArea = true
        break
      case CartValidationError.MissingNotes:
        acc.shouldAddNotes = true
        break
      case CartValidationError.OrderingWindowInvalid:
        acc.orderingWindowInvalid = true
        break
      case CartValidationError.ItemPriceChanged:
        acc.itemPriceChanged = true
        break
      case CartValidationError.ItemQuantityMin:
        acc.itemQuantityMinError = error.message
        break
      case CartValidationError.ItemQuantityMax:
        acc.itemQuantityMaxError = error.message
        break
      case CartValidationError.EmptyCart:
        acc.emptyCart = true
        break
      case CartValidationError.OrderingTypeUnavailable:
        acc.orderingTypeUnavailable = true
        break
      case CartValidationError.PurchasedCart:
        acc.purchasedCart = true
        break
      case CartValidationError.VenueClosed:
        acc.venueClosed = true
        break
      case CartValidationError.VenueNotTakingOrders:
        acc.venueNotTakingOrders = true
        break
      case CartValidationError.MissingTip:
        acc.shouldAddTip = true
        break
      case CartValidationError.TableNumberAllOff:
        acc.tableNumberAllOff = true
        break
      case CartValidationError.TableNumberAlcoholOff:
        acc.tableNumberAlcoholOff = true
        break
      case CartValidationError.UnavailableItem:
        acc.unavailableItemsErrors.push(error)
        break
      case CartValidationError.InvalidLoyaltyDiscount:
        acc.invalidLoyaltyDiscount = true
        break
      case CartValidationError.MissingUser:
        /* We don't use this error */
        break
      case CartValidationError.PosAdjustmentRequired:
        /* We don't use this error */
        break
      default:
        assertUnreachable(cartValidationError)
    }

    return acc
  }, cartValidationV2InitialValue(cart))

  return {
    ...result,
    unavailableItemsErrors: uniqBy(result.unavailableItemsErrors, 'message'),
  }
}

export const getQuantityStepperDisabledStates = ({
  cartItemValidation,
}: {
  cartItemValidation?: CartItemValidation
}) => {
  const quantityStepperDisabledStates = {
    canDecrement: true,
    canIncrement: true,
  }

  /*
    If no matching validation we still want to be able to change the quantity
  */
  if (!cartItemValidation) return quantityStepperDisabledStates

  return {
    canDecrement: cartItemValidation.canDecrement,
    canIncrement: cartItemValidation.canIncrement,
  }
}
