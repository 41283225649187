import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ErrorNotification } from '@mr-yum/frontend-ui'
import { AddressField } from 'components/HookForm/AddressField'
import { HookFormSubmitErrors } from 'components/HookForm/HookFormSubmitErrors'
import { InputField } from 'components/HookForm/InputField'
import {
  useOrderingTypeContext,
  useVenueContext,
} from 'contexts/VenueOrderContext'
import { config } from 'lib/config'
import {
  CurrentLocationPartsFragment,
  OrderingType,
  useAddOrUpdateLocationOnCartMutation,
  useVenueAddressValidationQuery,
} from 'lib/gql'
import React, { useCallback, useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'

import { AddressPostcodes } from './AddressPostcodes'
import { getValidationSchema } from './YourAddressUtils'

interface Props {
  onClose?: () => any
  location?: CurrentLocationPartsFragment | null
}

export const AddressForm = ({ onClose = () => false, location }: Props) => {
  const intl = useIntl()
  const { orderingType } = useOrderingTypeContext()
  const { venueSlug } = useVenueContext()

  const [{ data }] = useVenueAddressValidationQuery({
    variables: {
      venueSlug,
    },
    requestPolicy: 'cache-and-network',
  })
  const venue = data?.guestVenue

  const validationSchema = getValidationSchema(intl)
  type FormValues = Yup.InferType<typeof validationSchema>

  const [{ error }, addOrUpdateLocationOnCart] =
    useAddOrUpdateLocationOnCartMutation()

  const submitHandler = useCallback<SubmitHandler<FormValues>>(
    async ({ unitNumber, googlePlaceId, streetAddress }) => {
      const { error } = await addOrUpdateLocationOnCart({
        input: {
          venueSlug,
          orderingType,
          googlePlaceId,
          unitNumber,
          streetAddress,
        },
      })

      if (!error) {
        onClose()
        return
      }
    },
    [onClose, venueSlug, orderingType, addOrUpdateLocationOnCart],
  )

  let lat = -25
  let lng = 134
  if (venue?.location?.latitude && venue?.location?.latitude) {
    lat = venue.location.latitude
    lng = venue.location.latitude
  } else if (venue?.countryCode === 'ZA') {
    lat = -29
    lng = 25
  }

  const defaultValues = useMemo<FormValues>(
    () => ({
      unitNumber: location?.unitNumber ?? '',
      googlePlaceId: location?.googlePlaceId ?? '',
      streetAddress: location?.formattedAddress ?? '',
    }),
    [location],
  )

  const deliverySettings = venue?.orderingTypes?.find(
    (setting) => setting.orderingType === OrderingType.Delivery,
  )

  const postcodes = deliverySettings?.postcodes || []

  const form = useForm<FormValues>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(getValidationSchema(intl)),
  })

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form

  const onSelect = useCallback(
    (placeId: string) => {
      if (!placeId) {
        setValue('googlePlaceId', null)
        setValue('streetAddress', null)
      } else {
        setValue('googlePlaceId', placeId)
      }
    },
    [setValue],
  )

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="space-y-3">
          {error && <ErrorNotification fullWidth error={error} />}
          <HookFormSubmitErrors />
        </div>

        <AddressField
          name="streetAddress"
          googlePlacesApiKey={config.googlePlacesApiKey}
          onSelect={onSelect}
          clearable
          lat={lat}
          lng={lng}
          radius={50_000}
          maxDropdownHeight={150}
          placeholder={intl.formatMessage({
            defaultMessage: 'Search for address',
            id: 'D7lLal',
          })}
          label={intl.formatMessage({
            defaultMessage: 'Delivery address',
            id: 'v3tQCl',
          })}
          autoFocus
        />

        <InputField
          name="unitNumber"
          placeholder={intl.formatMessage({
            defaultMessage: 'Apartment number',
            id: 'ywUni4',
          })}
          label={intl.formatMessage({
            defaultMessage: 'Apartment number (optional)',
            id: 'AMm4WK',
          })}
          clearable={true}
        />

        {orderingType === OrderingType.Delivery && postcodes.length > 0 && (
          <AddressPostcodes postcodes={postcodes} />
        )}

        <Button
          type="submit"
          isLoading={isSubmitting}
          size="lg"
          fullWidth
          aria-label={intl.formatMessage({
            defaultMessage: 'Address confirm',
            id: 'H/tG0k',
          })}
        >
          <FormattedMessage id="N2IrpM" defaultMessage="Confirm" />
        </Button>
      </form>
    </FormProvider>
  )
}
