import { TransformType } from '@mr-yum/cdn-image'
import {
  createDinero,
  Currency,
} from '@mr-yum/frontend-core/dist/services/createDinero'
import {
  QuantityOnChangeEvent,
  QuantityStepper,
  TransparentQuantityStepper,
} from '@mr-yum/frontend-ui'
import { CartStaleWrapper } from 'components/Cart/shared'
import { ItemPriceWrapper } from 'components/MenuItem/shared'
import { DEFAULT_MAX_QUANTITY, ItemQuantity } from 'components/MenuItem/utils'
import { Image } from 'components/Shared/Image'
import { CartItemPartsFragment, LoyaltyDiscountPartsFragment } from 'lib/gql'
import React from 'react'

type Props = {
  isLoading?: boolean
  isShowingImage?: boolean
  isShowingPrice?: boolean
  cartItem: CartItemPartsFragment & {
    discounts?: LoyaltyDiscountPartsFragment[]
  }
  quantity: number
  currency?: Currency
  onChange: QuantityOnChangeEvent
} & Partial<ItemQuantity>

export const CartItem = ({
  isLoading,
  isShowingImage,
  isShowingPrice,
  cartItem,
  currency,
  quantity,
  minQuantity = 0,
  maxQuantity = DEFAULT_MAX_QUANTITY,
  onChange,
}: Props) => {
  const cdnImage = cartItem.item?.cdnImage ?? cartItem.upsell?.menuItem.cdnImage

  const totalDiscount = cartItem.discounts?.reduce(
    (acc, discount) => acc + discount.amountInCents,
    0,
  )

  const displayPrice = createDinero(
    cartItem.totalInCents - (totalDiscount ?? 0),
    currency,
  ).toFormat()

  return (
    <CartStaleWrapper loading={isLoading}>
      <div
        className="relative flex items-center py-3 text-foreground"
        data-testid="cart-item-container"
      >
        <div className="flex flex-1 items-center space-x-4">
          {isShowingImage && cdnImage && (
            <div className="inset relative h-16 w-16">
              <Image
                alt=""
                image={{
                  id: cdnImage.id,
                  originalImagePath: cdnImage.originalImagePath,
                  originalImageUrl: cdnImage.originalImageUrl,
                }}
                transform={TransformType.AVATAR}
                className="rounded-lg"
                layout="fill"
                objectFit="cover"
                sizes="64px"
              />
            </div>
          )}
          <div className="flex flex-[2] flex-col">
            <p className="mb-1 capitalize my-label-md">{cartItem.name}</p>
            {!!(
              cartItem.cartModifiers?.length || cartItem.cartUpsells?.length
            ) && (
              <div className="mb-1 text-foreground-subtle my-body-sm last:mb-0">
                {cartItem.cartModifiers?.map((cartModifier) => (
                  <div
                    key={cartModifier.id}
                    data-testid="modifier-name-and-quantity"
                  >
                    {cartModifier.quantity / cartItem.quantity}x{' '}
                    {cartModifier.name}
                  </div>
                ))}
                {cartItem.cartUpsells?.map((cartUpsell) => (
                  <div key={cartUpsell.id}>
                    {cartUpsell.quantity}x {cartUpsell.name}
                  </div>
                ))}
              </div>
            )}
            {isShowingPrice &&
              currency &&
              (cartItem.discounts?.length ? (
                <div>
                  <ItemPriceWrapper
                    data-testid="cart-item-total-display-price-wrapper"
                    className="items-center pr-4"
                  >
                    <span
                      className="foreground-disabled mr-2 line-through"
                      data-testid="cart-item-original-total-display-price"
                    >
                      {createDinero(cartItem.totalInCents, currency).toFormat()}
                    </span>
                    <div
                      className="foreground-subtle"
                      data-testid="cart-item-total-display-price"
                    >
                      {displayPrice}
                    </div>
                  </ItemPriceWrapper>
                  {cartItem.discounts.map((d) => (
                    <p
                      key={d.name}
                      className="text-sm italic text-semantic-success-foreground-bold"
                      data-testid="cart-item-discount-name"
                    >
                      {`${d.name} ${createDinero(
                        d.amountInCents * -1,
                        currency,
                      ).toFormat()}`}
                    </p>
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-between pr-4">
                  <p
                    className="text-foreground-subtle my-body-md"
                    data-testid="cart-item-total"
                  >
                    {createDinero(cartItem.totalInCents, currency).toFormat()}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className="-mt-3">
          <QuantityStepper
            min={minQuantity}
            max={maxQuantity}
            visible={!cartItem.isFree}
            value={quantity}
            containerProps={{
              'data-testid': 'cart-item-quantity',
              className: TransparentQuantityStepper.StepperClassNames.container,
            }}
            leftStepButton={
              <TransparentQuantityStepper.StepButton>
                <TransparentQuantityStepper.DecrementIcon />
              </TransparentQuantityStepper.StepButton>
            }
            rightStepButton={
              <TransparentQuantityStepper.StepButton>
                <TransparentQuantityStepper.IncrementIcon />
              </TransparentQuantityStepper.StepButton>
            }
            onChange={onChange}
          >
            <div className="w-8">
              <TransparentQuantityStepper.StepperValue>
                {quantity}
              </TransparentQuantityStepper.StepperValue>
            </div>
          </QuantityStepper>
        </div>
      </div>
    </CartStaleWrapper>
  )
}
