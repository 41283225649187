import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  shortText?: string | null
  number?: string | null
  area?: string | null
}

export const TableNumberLabel = ({ shortText, number, area }: Props) => {
  return (
    <>
      {shortText ? (
        shortText
      ) : (
        <FormattedMessage defaultMessage="Table" id="IeBejt" />
      )}{' '}
      {number} {area && `/ ${area}`}
    </>
  )
}
